import { PatientService } from '@/services/patient.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {
          firstname: '',
          lastname: ''
        };
      }
    }
  },
  data() {
    return {
      showDialog: false,
      receiveUSPS: false
    };
  },
  methods: {
    async save() {
      try {
        const {
          data
        } = await PatientService.receiveUSPS(this.rowData.id, !!this.receiveUSPS);
        this.$emit('show-toast', `Patient receive USPS set to: ${this.receiveUSPSText(data)}`, 'success');
        this.rowData.receiveUSPS = !!this.receiveUSPS;
        this.showDialog = false;
      } catch (error) {
        this.$emit('show-toast', error);
      }
    },
    receiveUSPSText(receiveUSPS) {
      return receiveUSPS ? 'Yes, they can receive USPS Deliveries' : 'No, they cannot receive USPS Deliveries';
    }
  }
};